<template>
  <div v-if="loaded" class="tour-stats">
    <div class="tour-stat">
      <Tourstat
        :title="'Hottest round'"
        :valueby="'Par'"
        :valuebydefault="true"
        :leaderboard="tourStats.bestRound"
        color="#D673AC"
        :sortprop="'par'"
        :ascending="true"
      />
    </div>
    <div class="tour-stat">
      <Tourstat
        :title="'Aces'"
        :valueby="'Tot'"
        :valuebydefault="true"
        :leaderboard="tourStats.aces"
        :color="$store.state.colors.parColors.ace.hex"
        :sortprop="'count'"
      />
    </div>
    <div class="tour-stat">
      <Tourstat
        :title="'Eagles'"
        :valueby="'Tot'"
        :valuebydefault="true"
        :leaderboard="tourStats.eagles"
        :color="$store.state.colors.parColors.eagle.hex"
        :sortprop="'count'"
      />
    </div>
    <div class="tour-stat">
      <Tourstat
        :title="'Birdies'"
        :valueby="'Avg'"
        :valuebydefault="true"
        :leaderboard="tourStats.birdies"
        :color="$store.state.colors.parColors.birdie.hex"
        :sortprop="'part'"
      />
    </div>
    <div class="tour-stat">
      <Tourstat
        :title="'Pars'"
        :valueby="'Avg'"
        :valuebydefault="true"
        :leaderboard="tourStats.pars"
        :color="$store.state.colors.parColors.par.hex"
        :sortprop="'part'"
      />
    </div>
    <div class="tour-stat">
      <Tourstat
        :title="'Least bogeys'"
        :valueby="'Avg'"
        :valuebydefault="true"
        :leaderboard="tourStats.leastBogeys"
        :color="$store.state.colors.parColors.bogey.hex"
        :sortprop="'part'"
      />
    </div>
    <div class="tour-stat">
      <Tourstat
        :title="'Circle hits'"
        :valueby="'Avg'"
        :valuebydefault="true"
        :leaderboard="tourStats.circleHits"
        :color="$store.state.colors.secondary.mist.hex"
        :sortprop="'part'"
      />
    </div>
    <div class="tour-stat">
      <Tourstat
        :title="'Conversions'"
        :valueby="'Avg'"
        :valuebydefault="true"
        :leaderboard="tourStats.circleHitConversions"
        :color="$store.state.colors.secondary.mist.hex"
        :sortprop="'part'"
      />
    </div>
    <div class="tour-stat">
      <Tourstat
        :title="'Inside putts'"
        :valueby="'Avg'"
        :valuebydefault="true"
        :leaderboard="tourStats.insidePutts"
        :color="$store.state.colors.secondary.mist.hex"
        :sortprop="'part'"
      />
    </div>
    <div class="tour-stat">
      <Tourstat
        :title="'Outside putts'"
        :valueby="'Avg'"
        :valuebydefault="true"
        :leaderboard="tourStats.outsidePutts"
        :color="$store.state.colors.secondary.mist.hex"
        :sortprop="'part'"
      />
    </div>
    <div class="tour-stat">
      <Tourstat
        :title="'OBs'"
        :valueby="'Avg'"
        :valuebydefault="true"
        :leaderboard="tourStats.outOfBounds"
        :color="$store.state.colors.secondary.dusk.hex"
        :sortprop="'part'"
      />
    </div>
  </div>
</template>

<script>
import Tourstat from "@/components/TourStat.vue";
export default {
  name: "PublicTourStats",
  data() {
    return {
      loaded: false,
      tourStats: null,
    };
  },
  components: {
    Tourstat,
  },
  computed: {},
  methods: {
    loadData() {
      this.$axios({
        data: {
          query: `
          {
            tour(tourId:"${this.$router.currentRoute.params.tourId}"){
              statsLeaderboards{
                bestRound{
                  userId
                  place
                  roundId
                  par
                  user{
                    id
                    firstName
                    lastName
                    profile{
                      club
                      pdgaNumber
                      pdgaRating
                    }
                  }
                }
                birdies{
                  count
                  part
                  userId
                  place
                  user{
                    id
                    firstName
                    lastName
                    profile{
                      club
                      pdgaNumber
                      pdgaRating
                    }
                  }
                }
                pars{
                  count
                  part
                  userId
                  place
                  user{
                    id
                    firstName
                    lastName
                    profile{
                      club
                      pdgaNumber
                      pdgaRating
                    }
                  }
                }
                aces{
                  count
                  part
                  userId
                  place
                  user{
                    id
                    firstName
                    lastName
                    profile{
                      club
                      pdgaNumber
                      pdgaRating
                    }
                  }
                }
                eagles{
                  count
                  part
                  userId
                  place
                  user{
                    id
                    firstName
                    lastName
                    profile{
                      club
                      pdgaNumber
                      pdgaRating
                    }
                  }
                }
                leastBogeys{
                  count
                  part
                  userId
                  place
                  user{
                    id
                    firstName
                    lastName
                    profile{
                      club
                      pdgaNumber
                      pdgaRating
                    }
                  }
                }
                circleHits{
                  count
                  part
                  userId
                  place
                  user{
                    id
                    firstName
                    lastName
                    profile{
                      club
                      pdgaNumber
                      pdgaRating
                    }
                  }
                }
                circleHitConversions{
                  count
                  part
                  userId
                  place
                  user{
                    id
                    firstName
                    lastName
                    profile{
                      club
                      pdgaNumber
                      pdgaRating
                    }
                  }
                }
                insidePutts{
                  count
                  part
                  userId
                  place
                  user{
                    id
                    firstName
                    lastName
                    profile{
                      club
                      pdgaNumber
                      pdgaRating
                    }
                  }
                }
                outsidePutts{
                  count
                  part
                  userId
                  place
                  user{
                    id
                    firstName
                    lastName
                    profile{
                      club
                      pdgaNumber
                      pdgaRating
                    }
                  }
                }
                outOfBounds{
                  count
                  part
                  userId
                  place
                  user{
                    id
                    firstName
                    lastName
                    profile{
                      club
                      pdgaNumber
                      pdgaRating
                    }
                  }
                }
              }
            }
          }
          `,
        },
      }).then((result) => {
        this.tourStats = result.data.data.tour.statsLeaderboards;
        this.loaded = true;
      });
    },
  },
  beforeMount() {
    this.loadData();
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.tour-stats {
  padding: 0 12px;
  .tour-stat {
    margin-bottom: 32px;
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
  .tour-stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .tour-stat {
      width: 48%;
      margin-bottom: 48px;
    }
  }
}

@media (min-width: 1200px) {
  .tour-stats {
    padding: 0;
    justify-content: flex-start;

    .tour-stat {
      width: 30.3%;
      margin-bottom: 64px;
      margin-right: 4.5%;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
</style>
