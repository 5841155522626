<template>
  <div class="tour-stat-container">
    <div class="header" :style="'background-color:' + color">
      <h3>{{ title }}</h3>
      <div class="view-all" @click="showAll = false" v-if="showAll">
        Show top 5
      </div>
      <div class="view-all" @click="showAll = true" v-else>Show all</div>
    </div>
    <table>
      <thead>
        <tr>
          <th class="pos">Pos</th>
          <th class="player">Player</th>
          <th class="value">{{ valueby }}</th>
        </tr>
      </thead>
      <transition-group name="flip-list" tag="tbody">
        <tr v-for="player in shownLeaderboard" :key="player.userId">
          <td class="pos">{{ player.place }}</td>
          <td class="player">
            {{ player.user.firstName }} {{ player.user.lastName }}
          </td>
          <td class="value">{{ displayValue(player[sortprop]) }}</td>
        </tr>
      </transition-group>
    </table>
  </div>
</template>

<script>
export default {
  name: "TourStat",
  props: ["title", "valueby", "leaderboard", "color", "sortprop", "ascending"],
  data() {
    return {
      showAll: false,
      sortDirection: "",
      sortBy: "",
    };
  },
  computed: {
    shownLeaderboard() {
      return this.showAll ? this.leaderboard : this.leaderboard.slice(0, 5);
    },
  },
  methods: {
    displayValue(value) {
      if (this.valueby == "Avg") {
        return Math.round(value * 100 * 100) / 100 + "%";
      }

      return value;
    },
  },
  mounted() {
    if (this.ascending) {
      this.sortDirection = "-";
    }
    this.sortBy = this.sortprop;
  },
};
</script>

<style scoped lang="scss">
.flip-list-move {
  transition: transform 1s;
}
.tour-stat-container {
  width: 100%;
  background-color: white;
  max-width: 480px;
  padding: 0px 0 0 0;
  position: relative;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    border-radius: 8px;
    padding: 0 16px;
    position: sticky;
    top: 0;

    .view-all {
      font-size: 14px;
      @include Gilroy-Regular;
      text-align: right;
      cursor: pointer;
    }

    h3 {
      font-size: 16px;
      @include Gilroy-Bold;
      text-align: center;
      margin: 0;
    }
  }

  table {
    width: 100%;
    table-layout: fixed;

    thead {
      th {
        font-size: 12px;
        @include Gilroy-Bold;
        color: $midnight;
      }
      .pos {
        width: 15%;
        text-align: center;
      }
      .player {
        width: auto;
      }
      .value {
        width: 18%;
        text-align: center;
      }
    }
    tbody {
      .pos {
        text-align: center;
        @include Gilroy-Regular;
      }
      .player {
        @include Gilroy-Bold;
      }
      .value {
        text-align: center;
        @include Gilroy-Bold;
      }
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
  .tour-stat-container {
    max-width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 0;
  }
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 768px) {
  .tour-stat-container {
    table {
      thead {
        th {
          font-size: 14px;
        }
      }
      tbody {
        td {
          border-bottom: 1px solid $cloud;
          height: 48px;
        }
      }
    }
  }
}
</style>
